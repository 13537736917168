/* eslint-disable */
import React from 'react'

import EyeDoctor from '../../images/people/eye-doctor.jpg'
import EyeDoctorMobile from '../../images/people/eye-doctor-mobile.jpg'
import ManSearching from '../../images/people/man-searching-online.jpg'
import ManSearchingMobile from '../../images/people/man-searching-online-mobile.jpg'
import TryingGlasses from '../../images/people/trying-on-glasses.jpg'
import TryingGlassesMobile from '../../images/people/trying-on-glasses-mobile.jpg'
import vibrantDarkBlueBackground from '../../images/backgrounds/vibrant-dark-blue.jpg'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import humana from '../../images/brand-logos/humana.svg'
import regence from '../../images/brand-logos/regence.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import united from '../../images/brand-logos/united-healthcare.svg'

import NarrowBanner from '../../components/NarrowBanner'

import {
  Billboard,
  Carousel,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title:
            'Find an in-network eye doctor near you | Clearlinkinsurance.com',
          description:
            "Find an in-network optometrist near you or check to see if your current eye doctor is part of your Medicare provider's network",
          canonical: 'https://clearlinkinsurance.com/',
          robots: 'follow,index',
        },
        path: '/find/find-an-optometrist',
        promoCode: '170010',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Find an in-network eye doctor near you | Clearlinkinsurance.com',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-split-content">
          <div className="wrapper">
            <Billboard
              backgroundColor="white"
              alignImageToBottom
              variant="split"
              image={
                <img
                  src={EyeDoctor}
                  alt="an EyeDoctor holds clipboard and smiles"
                />
              }
              mobileImage={
                <img
                  src={EyeDoctorMobile}
                  alt="an EyeDoctor holds clipboard and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Medicare: Find an eye doctor in-network near you
                  </Typography>
                  <Typography variant="h5">
                    Clearlink Insurance Agency makes it easy to find
                    conveniently located eye doctors who accept your plan’s
                    coverage.
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to="#find-an-eye-doctor"
                    color="pink"
                  >
                    Find an Eye Doctor
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>

        <div className="hide-nav-button" id="find-an-eye-doctor">
          <VariableContent
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">Find an eye doctor</Typography>
                <Typography variant="body">
                  Click or tap the button associated with your insurance carrier
                  to visit their eye doctor search page. From there, you can
                  find the selection of available optometrists and
                  ophthalmologists covered by your plan.
                </Typography>
              </>
            }
          >
            <Stack spacing="l">
              <Columns cardStyled mobileCarousel>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={aetna}
                    alt="Aetna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://eyedoclocator.aetnamedicarevision.com/aetnamedicare/en"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={cigna}
                    alt="Cigna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://hcpdirectory.cigna.com/web/public/consumer/directory/search"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={humana}
                    alt="Humana logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.humana.com/vision-insurance/find-an-eye-doctor"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={regence}
                    alt="Regence logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://providers.regence.com/healthsparq/public/#/one/insurerCode=RG_I&brandCode=RG_ID&productCode=&postalCode="
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={united}
                    alt="United Healthcare logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.uhc.com/find-a-doctor"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={wellcare}
                    alt="Wellcare logo"
                    style={{ maxWidth: '90px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.wellcarefindaprovider.com/navigate-a-network.html"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <SplitContent
          className="reverse-desktop"
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={ManSearching}
              alt="an elderly man searches for eye doctors online"
            />
          }
          mobileImage={
            <img
              src={ManSearchingMobile}
              alt="an elderly man searches for eye doctors online"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                How to search for an eye doctor
              </Typography>
              <Typography variant="body">
                When you click or tap the link associated with your insurance
                carrier, you’ll arrive at their “find an eye doctor” page. 
              </Typography>
              <Typography variant="body">
                From there, search by location to find a nearby, in-network
                optometrist or ophthalmologist who accepts your Medicare plan
                coverage.
              </Typography>
              <Typography variant="body">
                To complete your search, you may need to input the following
                information:
              </Typography>
              <List>
                <ListItem>Medicare ID card</ListItem>
                <ListItem>Plan year and type</ListItem>
                <ListItem>Home address</ListItem>
                <ListItem>ZIP code</ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          style={{
            backgroundImage: `url(${vibrantDarkBlueBackground})`,
            color: '#fff',
          }}
          alignMainContent="Center"
          mainContent={
            <>
              <Typography variant="h1">Find specialized care</Typography>
              <Typography variant="body">
                Not looking for an eye doctor? Use the button below to return to
                our Medicare provider search hub. There, you can search for
                hospitals, dentists, primary care physicians, pharmacies, and
                other providers covered by your plan.
              </Typography>
              <LinkButton variant="" to="/find" color="pink">
                Find a healthcare provider
              </LinkButton>
            </>
          }
        ></VariableContent>

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={TryingGlasses}
              alt="an elderly man tries on a new pair of glasses"
            />
          }
          mobileImage={
            <img
              src={TryingGlassesMobile}
              alt="an elderly man tries on a new pair of glasses"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Helpful tips for finding the right eye doctor
              </Typography>
              <Typography variant="body">
                When searching for the right eye doctor for you, the first thing
                to consider is whether you need to see an optometrist or an
                ophthalmologist.
              </Typography>
              <Typography variant="h6">Optometrists</Typography>
              <List>
                <ListItem>
                  Perform routine eye care, such as prescribing and fitting
                  glasses and contact lenses, and detecting and treating common
                  eye conditions
                </ListItem>
                <ListItem>
                  Focus on non-invasive, non-surgical eye care
                </ListItem>
              </List>
              <Typography variant="h6">Ophthalmologists</Typography>
              <List>
                <ListItem>
                  Diagnose and treat a wide range of eye conditions and
                  diseases,
                </ListItem>
                <ListItem>Manage more complex and severe eye issues</ListItem>
                <ListItem>
                  May perform eye surgeries, such as laser vision correction,
                  cataract surgery, and corneal transplants
                </ListItem>
              </List>
              <Typography variant="body">
                Once you’ve determined which type of eye doctor you’d like to
                see, choosing an eye-care provider is similar to choosing any
                other medical provider.
              </Typography>
              <List>
                <ListItem>Research ratings and reviews</ListItem>
                <ListItem>
                  Make preliminary office visits to evaluate staff and
                  facilities
                </ListItem>
                <ListItem>
                  Check out providers’ specialized service offerings to see that
                  they match with your preferences
                </ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          alignMainContent="Center"
          className="quote-slide-carousel"
        >
          <Carousel>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">Best day in my life!</Typography>
                  <Typography variant="body">
                    “Ms. Welch agent, helped me to trust that I can see my
                    chosen doctor or specialists if needed. Plus I have more
                    benefits than I did not have with previous insurance! Now
                    dental care, imperative for all healthcare needs!! A bonus
                    change… and savings!”
                  </Typography>
                  <Typography variant="body">From: Ina H., US</Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">Great customer service</Typography>
                  <Typography variant="body">
                    “Great customer service. Associate was very knowledgeable
                    about services offered. Associate made sure I understood my
                    options.”
                  </Typography>
                  <Typography variant="body">From: Khary H., US</Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">Excellent representative</Typography>
                  <Typography variant="body">
                    “Timothy was an excellent rep. He made the entire process
                    enjoyable. He helped make sure that I got the best deal. As
                    a veteran, he made me feel like I mattered and was able to
                    save me money on my Medicare Advantage Plan.”
                  </Typography>
                  <Typography variant="body">From: Jim K., US</Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
          </Carousel>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
